import styled from '@emotion/styled'
import { TableCell } from '@mui/material'
import { Text } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'

type TableCellTextProps = {
  text?: string | null
  colSpan?: number
  paddingLeft?: string
}

export function TableCellText({ text, colSpan, paddingLeft }: TableCellTextProps): ReactElement {
  return (
    <StyledTableCell colSpan={colSpan} paddingLeft={paddingLeft}>
      <Text variant="body2">{text || '-'}</Text>
    </StyledTableCell>
  )
}

const StyledTableCell = styled(TableCell)<{ paddingLeft?: string }>`
  padding-left: ${({ paddingLeft }) => paddingLeft};
`
